{
  "action": {
    "apply": "適用する",
    "back": "戻る",
    "cancel": "キャンセル",
    "change": "変更する",
    "confirm": {
      "delete": "本当に削除してよろしいですか？"
    },
    "create": "新規登録",
    "delete": "削除",
    "login": "ログイン",
    "logout": "ログアウト",
    "next": "続ける",
    "resetting": "再設定する",
    "save": "保存する",
    "submit": "提出する",
    "update": "更新する"
  },
  "coach": {
    "rehiring": {
      "successfully": "アカウントが登録されました"
    }
  },
  "contract": {
    "actions": {
      "agree": "希望する",
      "disagree": "希望しない"
    },
    "form": {
      "otherMethod": "契約書の確認方法を変更しました",
      "success": "契約が締結されました"
    },
    "format": {
      "contractLabel": "{{version}}（{{startDate}}〜{{endDate}}）",
      "name": "{{lastName}} {{firstName}}"
    },
    "message": {
      "noArchiveContracts": "締結済みの契約書はありません。",
      "noPendingContracts": "締結前の契約書の契約書はありません。"
    },
    "modal": {
      "message": "電子契約書を使用して契約書を締結することを希望しますか？",
      "title": "雇用契約書 兼 労働条件通知書"
    },
    "submit": "雇用契約書に合意する",
    "title": {
      "archive": "締結済みの契約書",
      "list": "雇用契約書",
      "main": "書類の確認",
      "pending": "締結前の契約書"
    }
  },
  "dashboard": {
    "heading": {
      "account": "アカウント管理",
      "shift": "シフト管理",
      "user": "ユーザー情報"
    }
  },
  "dependentDeductionDeclarationForm": {
    "choice": {
      "description": "年末調整にあたり、従業員は企業へ「給与所得者の扶養控除等（異動）申告書」の提出が必要です。\n副業・兼業などで2か所以上から給与をもらっていて、他社に提出する（またはすでに提出している）場合は、コノセルへの提出は不要です。\n\n給与所得者の扶養控除等（異動）申告書をコノセルに提出しますか？",
      "heading": "扶養控除申告書の提出先",
      "no": "コノセル以外の会社に提出する（している）",
      "yes": "コノセルに提出する"
    },
    "fileUploadedToConocer": {
      "description": "申告書の提出が完了しました。\nありがとうございました。\n\n申告書に関するお問い合わせは、\n下記メールアドレスまでご連絡ください。\ncorpinfra-group@conocer.co"
    },
    "form": {
      "error": {
        "choice": "申告の提出先を選択をしてください",
        "file": {
          "required": "ファイルをアップロードしてください",
          "size": "ファイルサイズが大きすぎます",
          "type": "PDF、JPEG、JPG、PNGのみアップロード可能です"
        }
      },
      "upload": {
        "description": {
          "A": {
            "description": "以下のリンクからPDFをダウンロードして、PDFフォームに直接入力する方法。PCでのみ入力可能で、スマホは非対応です。\nフォーム入力後、PDFをアップロードしてください。",
            "heading": "A. フォーム入力形式（PCのみ）",
            "link": "{{year}}分 給与所得者の扶養控除等（異動）申告書（入力用PDF）"
          },
          "B": {
            "description": "以下のリンクから、PDFをダウンロードして印刷して、記入する方法。\n手書きで記入後、スマホで撮影またはスキャンをして、画像データをアップロードしてください。",
            "heading": "B. 印刷して記入する形式",
            "link": "{{year}}分 給与所得者の扶養控除等（異動）申告書（PDF）"
          },
          "description": "以下のどちらかの方法で申告書をアップロードしてください。",
          "example": "記入例（赤太枠内にご記入ください）"
        },
        "heading": "扶養控除申告書のアップロード",
        "hint": "アップロード可能なファイル形式は、PDF、JPEG、JPG、PNGのみです。"
      }
    },
    "loading": "ファイルをアップロードしています...",
    "notApplicable": {
      "description": "申告書のアップロードは不要です。\n\n申告書に関する質問は、corpinfra-group@conocer.co にご連絡ください。"
    },
    "notSubmitToConocer": {
      "description": "申告書のアップロードは不要です。\n\n申告書に関するお問い合わせは、\n下記メールアドレスまでご連絡ください。\ncorpinfra-group@conocer.co"
    },
    "title": {
      "list": "扶養控除申告書",
      "main": "扶養控除申告書"
    }
  },
  "emailVerification": {
    "currentEmail": "現在のメールアドレス",
    "description": "以下の内容でメールアドレスを変更しますか？",
    "message": {
      "emailAlreadyVerified": "新しいメールアドレスに変更済みです。\nログインしてください。",
      "success": "メールアドレスが変更されました"
    },
    "newEmail": "新しいメールアドレス",
    "note": "メールアドレスを変更すると現在のメールアドレスは使用できなくなります。\n新しいメールアドレスでログインしてください。",
    "submit": "変更する",
    "title": "メールアドレスの変更"
  },
  "errors": {
    "actions": {
      "home": "トップへ戻る",
      "reload": "リロード"
    },
    "notFound": {
      "description": "お探しのページが見つかりませんでした。\nURLをお確かめください。",
      "heading": "お探しのページが見つかりません"
    },
    "signUp": {
      "invalid": "お探しのページは制限されています。"
    },
    "unexpected": {
      "description": "ページを再読み込みするか、一度ログアウトしてから再度お試しください。\n問題が解決しない場合は所属校の教室長にご連絡ください。",
      "heading": "予期しないエラーが発生しました"
    },
    "unexpectedToken": {
      "heading": "お探しのページが見つかりません",
      "message": "お探しのページが見つかりませんでした。\nURLに誤りがあるか、有効期限が切れています。\n所属校の教室長にお問い合わせください。"
    }
  },
  "forgotPassword": {
    "form": {
      "error": {
        "email": {
          "invalid": "正しいメールアドレスを入力してください。",
          "invalidUser": "このメールアドレスは登録されていません"
        }
      },
      "label": {
        "destinationAddress": "送信先"
      }
    },
    "message": {
      "sent": "メールを送信しました。\nメールに記載されている手順に従って\nパスワードを再設定してください。"
    },
    "note": "メールアドレスを入力してパスワードを再設定してください。\nその後の手順はメールで送信されます。",
    "title": "パスワードの再設定"
  },
  "format": {
    "honorificTitle": "さん",
    "name": "{{lastName}}{{firstName}}"
  },
  "login": {
    "error": {
      "invalidIdAndPassword": "メールアドレスまたはパスワードが違います"
    },
    "field": {
      "email": {
        "label": "メールアドレス"
      },
      "password": {
        "label": "パスワード"
      }
    },
    "forgotPassword": "パスワードを忘れた場合",
    "submit": "ログイン",
    "title": "ログイン"
  },
  "setting": {
    "emailSend": "メールを送信しました",
    "passwordChange": "パスワードを変更しました",
    "title": {
      "emailChange": "メールアドレスの変更",
      "passwordChange": "パスワードの変更"
    }
  },
  "shift": {
    "errors": {
      "baseShiftName": {
        "required": "テンプレート名を1文字以上20文字以下で入力してください"
      }
    },
    "format": {
      "calenderMonth": "{{month}}",
      "date": "{{month}}月{{date}}日（{{week}}）",
      "nameWithPeriod": "{{displayName}}（{{- start}}〜{{- end}}）",
      "period": "{{- start}}〜{{- end}}"
    },
    "labels": {
      "baseShiftList": "テンプレート一覧",
      "closed": "休校日",
      "flyer": "門パイ",
      "shiftRequestMonth": "シフト提出期間",
      "templateName": "テンプレート名",
      "templateSettings": "テンプレート設定"
    },
    "messages": {
      "applyTemplate": {
        "heading": "テンプレートを選択",
        "message": "テンプレートを使ってこの週のシフト希望を設定できます。"
      },
      "arrivalAndDepartureTimes": "出勤時刻は授業開始の10分前／退勤時刻は授業終了の10分後です。",
      "baseShiftCreated": "テンプレートを作成しました",
      "baseShiftDeleted": "テンプレートを削除しました",
      "baseShiftSaved": "テンプレートを更新しました",
      "confirmShiftRequests": "提出すると変更ができません。\n提出してもよろしいですか？\n（提出後にシフトを変更したい場合は教室長に相談してください）",
      "emptyBaseShifts": {
        "heading": "テンプレートが見つかりません",
        "message": "テンプレートは見つかりませんでした。\n新しいテンプレートを作成してください。"
      },
      "emptyFixedShift": {
        "heading": "勤務スケジュールが見つかりません",
        "message": "指定された月の勤務スケジュールが\n見つかりませんでした。\n別の月を選択してください。"
      },
      "fixedShiftRequests": {
        "heading": "このシフト希望は提出済みです",
        "message": "この週のシフトを変更したい場合は教室長に相談してください。"
      },
      "maxBaseShifts": "テンプレートは最大3件まで作成できます。",
      "shiftRequestsSaved": "シフト希望を保存しました",
      "shiftRequestsSubmitted": "シフト希望を提出しました",
      "templateApplied": {
        "heading": "テンプレートが適用されました",
        "message": "このシフト希望はまだ保存されていません。\nシフト希望を保存するには「保存する」または「確定する」をクリックしてください。"
      }
    },
    "placeholders": {
      "select": "選択してください",
      "templateName": "例）シフト1"
    },
    "title": {
      "baseShifts": {
        "edit": "テンプレートの設定",
        "list": "テンプレート",
        "new": "テンプレートの登録"
      },
      "fixedShift": {
        "list": "勤務スケジュール"
      },
      "shiftRequests": {
        "edit": "シフト希望（{{month}}{{week}}）",
        "list": "シフト希望"
      }
    }
  },
  "signUp": {
    "finish": {
      "header": "ユーザー登録が完了しました 🎉",
      "message": "ユーザー登録お疲れさまでした。\n\nコーチアプリを使用するには\nログインが必要です。"
    },
    "verification": {
      "header": "コノ塾へようこそ 🎉",
      "messages": {
        "continue": "このメールアドレスで登録を続けますか。",
        "welcome": "コーチアプリの利用を開始するために\nユーザー登録が必要です。"
      }
    }
  },
  "user": {
    "attrs": {
      "bankAccountType": {
        "checking": "当座",
        "saving": "普通"
      },
      "centerName": "所属校",
      "gender": {
        "female": "女性",
        "male": "男性"
      }
    },
    "form": {
      "error": {
        "bankAccountName": {
          "notKana": "カタカナのみ使用してください",
          "required": "口座名義（カナ）を入力してください"
        },
        "bankAccountNumber": "口座番号を入力してください",
        "bankAccountType": "口座種別を入力してください",
        "bankBranchName": "支店名を入力してください",
        "bankName": "金融機関名を入力してください",
        "birthDate": {
          "invalid": "正しい生年月日を入力してください"
        },
        "blockNumber": "番地を入力してください",
        "city": "市区町村を入力してください",
        "closestStation": "最寄駅を入力してください",
        "email": {
          "exists": "このメールアドレスはすでに使用されています",
          "invalid": "正しいメールアドレスを入力してください",
          "sameEmail": "現在とは別のメールアドレスをご利用ください"
        },
        "emergencyContactName": "名前を入力してください",
        "emergencyContactRelationship": "続柄を入力してください",
        "firstName": {
          "invalid": "「名」に使用できない漢字（{{invalidChars}}）が含まれているため、新字体を使用してください",
          "required": "「名」を入力してください"
        },
        "firstNameKana": "「めい」を入力してください",
        "gender": "性別を入力してください",
        "lastName": {
          "invalid": "「姓」に使用できない漢字（{{invalidChars}}）が含まれているため、新字体を使用してください",
          "required": "「姓」を入力してください"
        },
        "lastNameKana": "「せい」を入力してください",
        "password": {
          "lowerCase": "英小文字を1文字以上使用してください",
          "min": "8文字以上入力してください",
          "number": "数字を1文字以上使用してください",
          "required": "「パスワード」を入力してください",
          "upperCase": "英大文字を1文字以上使用してください"
        },
        "passwordConfirmation": {
          "mismatch": "パスワードが一致しません",
          "required": "「パスワード（確認用）」を入力してください"
        },
        "prefecture": "都道府県を選択してください",
        "residenceAddress": {
          "blockNumber": "住民票の番地を入力してください",
          "city": "住民票の市区町村を入力してください",
          "prefecture": "住民票の都道府県を選択してください",
          "zipCode": {
            "max": "住民票の郵便番号を正しく入力してください",
            "required": "住民票の郵便番号を入力してください"
          }
        },
        "tel": {
          "invalid": "電話番号を正しく入力してください",
          "required": "電話番号を入力してください"
        },
        "zipCode": {
          "max": "郵便番号を正しく入力してください",
          "required": "郵便番号を入力してください"
        }
      },
      "hint": {
        "email": "新しいメールアドレスを入力してください。\nその後の手順は新しいメールアドレスに送信されます。",
        "password": "•8文字以上入力してください\n•英小文字を1文字以上使用してください\n•英大文字を1文字以上使用してください\n•数字を1文字以上使用してください",
        "university": "学生以外は入力を省略できます"
      },
      "label": {
        "address": "住所",
        "bankAccountForSalaryDeposit": "給与受取口座",
        "bankAccountNameKana": "口座名義（カナ）",
        "bankAccountNumber": "口座番号",
        "bankAccountType": "口座種別",
        "bankBranchName": "支店名",
        "bankName": "金融機関名",
        "birthDate": "生年月日",
        "blockNumber": "番地",
        "buildingNumber": "建物名・部屋番号",
        "city": "市区町村",
        "closestStation": "最寄駅",
        "currentEmail": "現在のメールアドレス",
        "email": "メールアドレス",
        "emergencyContact": "緊急連絡先",
        "firstName": "名",
        "firstNameKana": "めい",
        "gender": "性別",
        "lastName": "姓",
        "lastNameKana": "せい",
        "name": "名前",
        "newEmail": "新しいメールアドレス",
        "newPassword": "新しいパスワード",
        "password": "パスワード",
        "passwordConfirmation": "パスワード（確認用）",
        "prefecture": "都道府県",
        "relationship": "続柄",
        "residenceAddress": "住民票住所",
        "residenceAddressSameAsAddress": "住所と同じ",
        "residenceAddressSetting": "住民票住所の設定",
        "tel": "電話番号",
        "university": "所属大学",
        "zipCode": "郵便番号"
      },
      "placeholder": {
        "bankAccountNameKana": "コノジュク ハナコ",
        "bankAccountNumber": "1234567",
        "bankBranchName": "◯◯支店",
        "bankName": "◯◯銀行",
        "birthDate": "2007-08-30",
        "blockNumber": "1299-1",
        "buildingNumber": "トクヤビル103",
        "city": "町田市本町田",
        "closestStation": "JR横浜線・小田急線 町田駅",
        "emergencyContactName": "コノ塾 太郎",
        "emergencyContactRelationship": "父",
        "firstName": "花子",
        "firstNameKana": "はなこ",
        "lastName": "コノ塾",
        "lastNameKana": "このじゅく",
        "prefecture": "選択してください",
        "tel1": "080",
        "tel2": "1234",
        "tel3": "5678",
        "university": "◯◯大学 文理学部 教育学科",
        "zipCode1": "194",
        "zipCode2": "0032"
      },
      "success": "登録情報が更新されました"
    },
    "title": {
      "address": "住所",
      "bankAccountForSalaryDeposit": "給与受取口座",
      "emergencyContact": "緊急連絡先",
      "password": "パスワード",
      "profile": "プロフィール"
    }
  }
}
